import { Switch, Route,useHistory, useLocation } from "react-router-dom";
import Logout from "./pages/Logout";
import GuLogin from "./pages/GuLogin";
import React,{ useState,useCallback,useEffect, Suspense, lazy} from "react";
import caAPI from "./services/caAPI";
import { trackPromise } from "react-promise-tracker";

export default function Routes() {
  const [tokenValido, setTokenValido] = useState(false);
  const history = useHistory();
  const location = useLocation()
  const VoipModal = lazy(() => import('./components/VoipModal'));
  const Call = lazy(() => import('./pages/Call'));

  const valida = useCallback(async function() {
    const queryParams = new URLSearchParams(location.search);
    let tokenUrl = queryParams && JSON.parse(queryParams.get('token'));
    if(tokenUrl){
      caAPI.setLocalState('token', tokenUrl);
    }

    let usuarioUrl = queryParams && queryParams.get('idUsuario');
    if(usuarioUrl){
      caAPI.setLocalState('idUsuario', usuarioUrl);
    }
    
    let tokenAtual = caAPI.getLocalState("token");
    if (
      caAPI.getLocalState("token") !== null
    ) {
      caAPI
        .validaToken(tokenAtual)
        .then(async (res) => {
          const valido = res?.data?.valido || false;
          if(valido){
            caAPI.refreshApi();
            caAPI.setLocalState("permissoes", res.data.permissoes);
            setTokenValido(valido);
          }else{
            setTokenValido(valido);
            throw "Acesso não autorizado";
          }
        })
        .catch((err) => {
          console.log(err);
          caAPI.logout();
        });
    } else {
      caAPI.logout();
    }
  }, [location]);

  useEffect(() => {
    if (!history.location.pathname.includes("gu-login")) {
      trackPromise(valida());
    }
  }, [history.location.pathname, valida]);

  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <Switch>
        <Route path="/gu-login/:token" component={GuLogin} />
        {tokenValido ? (
          <>
             {/* <Route path="/" component={() => <Call />}/> */}
             <Route path="/receber" component={() => <VoipModal />}/>
          </>
        ): (
          
            <Route path="/logout" component={Logout}/>
           
        )}
      </Switch>
    </Suspense>
  );
}
