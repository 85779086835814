import React, { createContext, useCallback, useEffect, useState, useContext } from "react";
import caAPI from "../services/caAPI";
import moment from "moment";
import Swal from "sweetalert2";
import { trackPromise } from 'react-promise-tracker';
import { PerfilUsuarioService } from "../services/PerfilUsuarioService";

const atualizaLista = 1;

export const AtendimentoContext = createContext({});

export function AtendimentoProvider({ children }) {

  const [atendimentoAtivo, setAtendimentoAtivo] = useState();

  const handleReloadAtendimento = function () {
    if(atendimentoAtivo && atendimentoAtivo.id){
      caAPI.atendimento.find(atendimentoAtivo.id).then(res => {
          setAtendimentoAtivo(res.data);
        }).catch(err => {
      })
    }
  }
  const limparAtendimentoAtivo = () => {
    setAtendimentoAtivo(null);
  }

  return (
    <AtendimentoContext.Provider value={{
      handleReloadAtendimento,
      setAtendimentoAtivo,
      limparAtendimentoAtivo,
      atendimentoAtivo
    }}>
      {children}
    </AtendimentoContext.Provider>
  )
}
