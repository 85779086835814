import React, { createContext, useCallback, useContext, useState, useEffect, useMemo } from "react";
import { Device } from '@twilio/voice-sdk';
import caAPI from "../services/caAPI";
import Swal from "sweetalert2";
import { AtendimentoContext } from "./AtendimentoContext";
import { useStopwatch } from 'react-timer-hook';
import { trackPromise } from "react-promise-tracker";
import phoneRing from "../assets/Audio/modern-phone.mp3"
import { useLocation } from 'react-router-dom'

export const VoipContext = createContext({});
export function VoipProvider({ children }) {
  const [inCallStatus, setInCallStatus] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [connection, setConnection] = useState(false);
  const [triggerCloseModalIncoming, setTriggerCloseModalIncoming] = useState(null);
  const { atendimentoAtivo, setAtendimentoAtivo } = useContext(AtendimentoContext);
  const [twilioCallClient, setTwilioCallClient] = useState(null)
  const [twilioRespondeClient, setTwilioRespondeClient] = useState(null)
  const [chamadasPerdidas, setChamadasPerdidas] = useState([]);
  const location = useLocation();

  const audio = useMemo(()=>{
    return new Audio(phoneRing);
  },[phoneRing]);

  audio.loop = true;
  const {
      seconds,
      minutes,
      isRunning,
      start,
      pause,
      reset,
    } = useStopwatch({ autoStart: false });

  const registrarClient = async(client) => {
    await client.register();
  }  

  useEffect(() => {
    if(twilioRespondeClient){
      registrarClient(twilioRespondeClient);
    }

  }, [twilioRespondeClient])  

  const handleCloseModalIncoming = () => {
    const trigger = Math.random();
    return setTriggerCloseModalIncoming(trigger);
  };

  const clienteTwilioFactory = useCallback((tokenTwilio, opcoes = {}, idAtendimentoRecebido = null) => {
    const device = new Device(tokenTwilio, opcoes);
    device.on('incoming', (call) => {
      audio.play();
      call.on('cancel', () => {
        handleCloseModalIncoming();
        setInCallStatus(false);
        setConnection(false);
        setIsMuted(false);
        device.destroy();
      });
  
      call.on('disconnect', () => {
        handleCloseModalIncoming();
        setInCallStatus(false);
        setConnection(false);
        setIsMuted(false);
        device.destroy();
      });
  
      call.on('accept', (call) => {
        audio.pause();
        if(idAtendimentoRecebido){
          let atendenteId = { atendente: caAPI.getLocalState('idUsuario') }
          trackPromise(
            caAPI.atendimento.atender(atendenteId, idAtendimentoRecebido).then(res => {
              const novoAtendimentoAtivo = Object.assign({}, res.data);
              setAtendimentoAtivo(novoAtendimentoAtivo);
              setInCallStatus(true);
            }).catch(err => {
              Swal.fire({
                titleText: "Erro",
                text: "Houve um problema ao atribuir o atendente  " + err,
                icon: 'error'
              });
            })
          );
        }
      });
      setConnection(call);
    });
 
    device.on('unregistered', () => {
      setConnection(null)
      if( window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage('close')
      }
    })

    device.on('registered', () => {
      if(idAtendimentoRecebido){
        caAPI.atendimento
          .chamar(idAtendimentoRecebido)
          .then(
            (res) => console.log("Chamado")
          ).catch(
            (error) => handleLigacaoPerdida()
          );
      }
    })

    device.on('error', (error, call) => {
      console.log("ERROR Twilio: " + error.message);
      handleLigacaoPerdida();
    });

    device.audio.on('deviceChange', () => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
       console.log("Ainda com permissão")
      })
      .catch((error) => {
        if (error.name === 'NotAllowedError') {
            console.error('Permissão negada pelo usuário.');
        } else if (error.name === 'NotFoundError') {
            console.error('Nenhum dispositivo de áudio foi encontrado.');
        } else if (error.name === 'OverconstrainedError') {
            console.error('As restrições solicitadas não puderam ser atendidas.');
        } else {
            console.error('Erro desconhecido:', error);
        }
        handleLigacaoPerdida();
      });
    });

    device.audio.incoming(false);

    return device;
  }, [atendimentoAtivo,  setAtendimentoAtivo]);

  // useEffect(()=>{
  //   if(!(connection) || (connection.customParameters.get('direcao') !== 'enviado')){
  //     const twilioDevice = clienteTwilioFactory();
  //     setTwilioCallClient(twilioDevice);
  //   }
  // }, [atendimentoAtivo])

  const handleLigacaoPerdida = () => {
    audio.pause();
    if(twilioRespondeClient && twilioRespondeClient.calls.length){
      const conexao = twilioRespondeClient.calls.shift();
      conexao.reject()
      twilioRespondeClient.disconnectAll();
    }
    setConnection(null);
    twilioRespondeClient.destroy();
    setTwilioRespondeClient(null);
    Swal.fire({
      titleText: "Ligação Perdida",
      icon: 'warning',
      showCloseButton: true
    }).then(()=>{
      if( window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage('close')
      }
    })
  }

  const receiveCall = useCallback(async (connection_socket, idAtendimento) => {
    if(!twilioRespondeClient){
      try{
        let opcoes = {
          codecPreferences: ["opus", "pcmu"],
          logLevel: 0,
          appName: "Microfrontend Conecta",
          enableImprovedSignalingErrorPrecision: true,
          allowIncomingWhileBusy: true,
          maxCallSignalingTimeoutMs: 3000
        };
        
        const twilioDevice = clienteTwilioFactory(connection_socket, opcoes, idAtendimento);
        setTwilioRespondeClient(twilioDevice)
      }catch(error){
        console.log(error);
        handleLigacaoPerdida();
      }
    }
  }, [clienteTwilioFactory, twilioRespondeClient]);

  const readyCallback = useCallback(() => {

    if(atendimentoAtivo){
      const dadosVoip = {
        id_atendimento: atendimentoAtivo.id,
        id_cliente: caAPI.getLocalState('idCliente'),
        contato_externo: atendimentoAtivo.contato_destino.trim(),
        contato_interno: atendimentoAtivo.contato_origem.trim(),
        direcao: 'enviado'
      };


      twilioCallClient.connect(dadosVoip);
    }
  }, [atendimentoAtivo, twilioCallClient]);

  const handleCall = () => {
    caAPI.getTokenCcd().then(res => {
      twilioCallClient.setup(res.data, {
        debug: true,
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });
      setInCallStatus(true);
    }).catch(err => {
      Swal.fire({
        titleText: "Erro",
        text: "Houve um problema ao buscar o token da chamada " + err,
        icon: 'error'
      });
    })
    twilioCallClient.ready(() => {
      readyCallback();
    });
    twilioCallClient.error(function (error) {
      console.log("ERROR Twilio: " + error.message);
    });
  };

  function hangUp() {
    if(twilioRespondeClient){
      twilioRespondeClient.disconnectAll();
    }
    if(twilioCallClient){
      twilioCallClient.disconnectAll();
    }
    setInCallStatus(false);
  }


  function handleMuteCall() {
    if(connection){
      const novoMutar = !isMuted;
      connection.mute(novoMutar)
      setIsMuted(connection.isMuted());
    }
  }

  function handleRejeitar() {
    if (connection) {
      connection.reject();
    }
    if(twilioRespondeClient && twilioRespondeClient.calls.length){
      const conexao = twilioRespondeClient.calls.shift();
      conexao.reject()
      twilioRespondeClient.disconnectAll();
    }
    if(twilioCallClient && twilioCallClient.state == 'registered'){
      twilioCallClient.disconnectAll();
    }
  }

  function answerCall() {
    connection.accept();
    setInCallStatus(true);
    start();
  }

  function adicionaChamadaPerdida (chamada = {}) {
    setChamadasPerdidas((valorAnterior) => {
      return [...valorAnterior, chamada]
    })
  }

  return (
    <VoipContext.Provider value={{
      receiveCall,
      inCallStatus,
      handleCall,
      hangUp,
      handleMuteCall,
      isMuted,
      setIsMuted,
      answerCall,
      handleRejeitar,
      triggerCloseModalIncoming,
      connection,
      twilioRespondeClient,
      minutes,
      seconds,
      adicionaChamadaPerdida,
      chamadasPerdidas,
      handleLigacaoPerdida
    }}>
      {children}
    </VoipContext.Provider>
  )
}
