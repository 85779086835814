import React, {createContext} from "react";
import {WebSocketProvider} from './WebSocketContext';
import {AtendimentoProvider} from "./AtendimentoContext";
import {VoipProvider} from "./VoipContext";
// import {ModulosProvider} from "./ModulosContext";
// import {MeioComunicacaoProvider} from "./MeioComunicacaoContext";
// import {SituacaoAtendimentoProvider} from "./SituacaoAtendimentoContext";
// import {TipoAtendimentoProvider} from "./TipoAtendimentoContext";
// import {TipoHistoricoAtendimentoProvider} from "./TipoHistoricoAtendimentoContext";
// import { RelatorioProvider } from "./RelatorioContext";
// import { TagsProvider } from "./TagsContext";
// import { ItensAtalhoProvider } from "./ItensAtalhosContext";
// import { EmpreendimentoProvider } from "./EmpreendimentosContext";
// import { CampanhaProvider } from "./CampanhasContext";
// import { TiposAnexosTemplateProvider } from "./TiposAnexosTemplateContext";
// import { MapeamentoErrosTwillioProvider } from "./MapeamentoErrosTwillioContext";

export const AppContext = createContext({});

export function AppContextProvider ({children}) {

  return (
    <AppContext.Provider value={{}}>
      <WebSocketProvider>
        <AtendimentoProvider>
          <VoipProvider>
              {children}
          </VoipProvider>
        </AtendimentoProvider>
      </WebSocketProvider>
    </AppContext.Provider>
  )
}
