import React, {createContext, useEffect, useState} from "react";
import Pusher from "pusher-js";
import caAPI from "../services/caAPI";
import { useLocation } from "react-router-dom";

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  wsHost: 'wsdommus.dommus2.com.br',
  wssPort: 6001,
  forceTLS: true,
  enabledTransports: ['ws', 'wss']
})
export const WebSocketContext = createContext({});
export function WebSocketProvider ({children}) {

  const [channel, setChannel] = useState(pusher);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let clienteUrl = queryParams && JSON.parse(queryParams.get('idCliente'));
    if(clienteUrl){
      caAPI.setLocalState('idCliente', clienteUrl)
    }
    const idCliente = clienteUrl ?? caAPI.getLocalState('idCliente') ?? null;
    if(idCliente){
      let channel_name = 'inquilino-'+idCliente.toString().padStart(4, '0');
      setChannel(pusher.subscribe(channel_name));
    }    
  }, [ setChannel, location])

  return (
    <WebSocketContext.Provider value={{channel}}>
      {children}
    </WebSocketContext.Provider>
  )
}
